@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');

.contact .navbar{
   background-color: black;
}

.contact .nav-links :hover {
   background-color: rgb(46, 46, 46);
   
 }

.contact h1{
   padding-top: 80px;
   padding-left: 50px;
   font-family: 'Judson', serif;
   color: #801214;
}

.Contact {
   display: flex;
   justify-content: center;
   align-items: center;
   min-height: 100vh;
   font-family: 'Comfortaa', cursive;
 }

.contact .contact-form {
   position: relative;
   z-index: 1;
   max-width: 360px;
   margin: 0 auto;
   padding: 45px;
   text-align: center;
   border-radius: 10px;
   margin-top: 1%;
   margin-bottom: 5%;
}

.contact .form-contact{
   position: relative;
   z-index: 1;
   max-width: 360px;
   margin: 0 auto;
   padding: 45px;
   text-align: center;
   border-radius: 10px;
   margin-top: 1%;
   margin-bottom: 5%;
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.contact-form input,
.contact-form textarea {
   outline: 0;
   background: #f2f2f2;
   width: 100%;
   border: 0;
   margin: 0 0 15px;
   padding: 15px;
   box-sizing: border-box;
   font-size: 14px;
   border-radius: 5px;
}

.contact-form input:focus,
.contact-form textarea:focus {
   background: #dbdbdb;
}

.contact-form button {
   font-family: 'Comfortaa', cursive;
   text-transform: uppercase;
   outline: 0;
   background: #801214;
   width: 100%;
   border: 0;
   padding: 15px;
   color: #FFFFFF;
   font-size: 14px;
   border-radius: 5px;
   cursor: pointer;
   transition: all 0.3 ease;
}

.contact-form button:hover,
.contact-form button:active {
   background: #b34345;
}

.contact-form h2 {
   margin-bottom: 28px;
   color: #801214;
}

.contact-form p.error {
   margin: 0 0 10px 0;
   text-align: center;
   font-size: 12px;
   color: red;
}

.message-container {
   background-color: #f9f9f9;
   border: 1px solid #e1e1e1;
   border-radius: 8px;
   padding: 20px;
   margin-bottom: 20px;
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
 }
 
 .message-container p {
   margin: 10px 0;
 }
 
 .message-container button {
   margin-top: 10px;
 }

.toast-notification {
   position: fixed;
   bottom: 20px;
   left: 50%;
   transform: translateX(-50%);
   background-color: black;
   color: white;
   padding: 10px 20px;
   border-radius: 4px;
   z-index: 1050;
}

.sort-button-container {
   display: flex;
   justify-content: center;
   align-items: center;
}

.contact .sort-options {
   font-family: 'Comfortaa', cursive;
   background: #f2f2f2;
   border: 2px solid #801214; 
   color: #333;
   padding: 10px;
   margin: 0 10px;
   border-radius: 5px;
   cursor: pointer;
   outline: none;
   display: inline-block;
}

.message-read {
   background-color: #e7e6e6;
}
 