.About .navbar {
   position: fixed;
   top: 0;
   width: 100%;
   z-index: 1000;
   background-color: black;
 }
 
.About .nav-links :hover{
  background-color: rgb(46, 46, 46);
}

.About h1{
  padding-top: 80px;
  padding-left: 50px;
  font-family: 'Judson', serif;
  color: #801214;
}

.About .location-name{
  font-family: 'Comfortaa', cursive;
  color: #801214;
  padding-bottom: 10px;
}

.location{
  padding-left: 55px;
  padding-bottom: 20px;
}

.location .input-location{
  font-family: 'Comfortaa', cursive;
  margin-right: 15px;
  outline: 0;
  background: #f2f2f2;
  width: 20%;
  border: 0;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.location-submit {
  font-family: 'Comfortaa', cursive;
  text-transform: uppercase;
  outline: 0;
  background: #801214;
  width: 11%;
  border: 0;
  border-radius: 5px;
  padding: 15px;
  color: #FFFFFF;
  font-size: 11px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.location-submit:active {
  background: #b34345;
}

.editor-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 5%;
}

.editor {
  width: 90%;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
}

.editor-submit {
  font-family: 'Comfortaa', cursive;
  text-transform: uppercase;
  outline: 0;
  background: #801214;
  width: 11%;
  border: 0;
  border-radius: 5px;
  padding: 15px;
  color: #FFFFFF;
  font-size: 11px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.editor-container-display {
  align-items: flex-start;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 3%;
  border-radius: 5%;
}

.content-display {
  margin-top: 20px;
  padding: 15px;
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-align: left; /* Align text to the left */
}

.content-display p {
  color: #333;
}

.content-display strong {
  color: #000;
}

.toast-message {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 1060;
}