@import url('https://fonts.googleapis.com/css2?family=Judson:ital,wght@0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');

body {
  font-family: 'Judson', serif;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 40px;
  position: fixed;
  width: 100%;
  top: 0;
  transition: background-color 0.5s ease;
  z-index: 10;
}

.church-name a {
  text-decoration: none;
}

.church-name a,
.church-name a:visited,
.church-name a:hover,
.church-name a:active,
.church-name a:focus {
  text-decoration: none;
  color: White;
}


.transparentNav {
  background-color: transparent;
}

.darkNav {
  background-color: black;
}

.darkNav .church-name, .darkNav .nav-links a {
  color: white;
}

.nav-links a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  margin: 0 15px;
}

.church-name{
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: 19px;
  margin: 0 15px;
  margin-left: -49%;
}

.logo {
  padding: 10px;
  width: 60px;
  height: auto;
}

.slide-container {
  position: relative;
  z-index: 1;
  margin-top: 0;
}

.each-slide > div {
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100vh;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.what-we-believe {
  text-align: center;
  font-size: 25px;
  color: gray;
  margin-top: 70px;
}

.what-we-believe-info {
  font-family:'Segoe UI', sans-serif;
  text-align: center;
  font-size: 15px;
  color: gray;
  margin: -15px 250px;
}

.container {
  margin-top: 50px;
  display: flex;
}

.white-box {
  flex: 1;
  height: 400px;
  background-color: white;
  margin: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  position: relative;
  perspective: 1000px;
  transition: transform 0.8s;
  transform-style: preserve-3d; 
}

.white-box:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.white-box.flipped {
  transform: rotateY(180deg);
}

.white-box .front,
.white-box .back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.white-box .front {
  z-index: 2;
  transform: rotateY(0deg);
}

.white-box .back {
  transform: rotateY(180deg);
}

.white-box .back p{
  font-size: 15px;
  padding: 0 10px;
}

.white-box h1{
  text-align: center;
  font-size: 20px;
  color: gray;
}

.front-image {
  width: 70%;
  height: auto;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
  display: block;
  margin: auto;
}

.front-image:hover {
  background-color: lightgray;
}

.statement-of-faith h1{
  text-align: center;
  font-size: 45px;
  color: gray;
  margin-top: 50px;
}

.statement-of-faith p{
  text-align: center;
  margin-left: 50px;
  margin-right: 50px;
}

.each-slide {
  position: relative;
  height: 100vh;
}

.remove-button {
  position: absolute;
  top: 20%;
  right: 10px;
  padding: 5px 10px;
  background: rgba(255,255,255,0.7);
  color: #000;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: bold;
}

 .add-button {
  position: absolute;
  top: 105%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  cursor: pointer;
  padding: 10px 20px;
  background-color: #fff;
  color: #000;
  border: 2px solid #000;
  border-radius: 5px;
  font-weight: bold;
}

.toast-message {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 1060;
}