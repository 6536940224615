@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Judson:ital,wght@0,400;0,700;1,400&display=swap');

.Sermon .navbar{
   background-color: black;
   color: black;
}

.Sermon .nav-links :hover{
  background-color: rgb(46, 46, 46);
}

.Sermon h1{
   padding-top: 70px;
   padding-left: 50px;
   font-family: 'Judson', serif;
   color: #801214;
}

.no-scroll {
  overflow: hidden;
}

.Sermon .form, .sermon-form {
  position: absolute;
  z-index: 1051; 
  background: #FFFFFF;
  border-radius: 10px;
  max-width: 360px;
  margin: 0 auto 100px;
  padding: 45px;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  top: 50%; 
  left: 50%;
  transform: translate(-50%, -50%);
}
 
.form input, .sermon-form input, .sermon-form select {
   outline: 0;
   background: #f2f2f2;
   width: 100%;
   border: 0;
   border-radius: 5px;
   margin: 0 0 15px;
   padding: 15px;
   box-sizing: border-box;
   font-size: 14px;
   font-family: 'Comfortaa', cursive;
}
 
.form input:focus, .sermon-form input:focus, .sermon-form select:focus {
   background: #dbdbdb;
}

.form button, .sermon-form button {
   font-family: 'Comfortaa', cursive;
   text-transform: uppercase;
   outline: 0;
   background: #801214;
   width: 100%;
   border: 0;
   border-radius: 5px;
   padding: 15px;
   color: #FFFFFF;
   font-size: 14px;
   -webkit-transition: all 0.3 ease;
   transition: all 0.3 ease;
   cursor: pointer;
}
 
.form button:active, .sermon-form button:active {
   background:#b34345;
}

.form span, .sermon-form span {
   font-size: 40px;
   color: #801214;
   margin-bottom: 25px;
   display: block;
}

.form p.error, .sermon-form p.error {
   margin: 0 0 10px 10px;
   text-align: left;
   font-size: 10px;
   color: red;
}

.sermon-form label {
   display: block;
   margin-bottom: 5px;
   color: #333;
   font-size: 16px;
   font-family: 'Comfortaa', cursive;
}

.toggle-form-icon {
   position: fixed;
   bottom: 20px;
   left: 20px;
   width: 60px;
   height: 60px;
   background-color: white;
   border-radius: 30%;
   display: flex;
   align-items: center;
   justify-content: center;
   box-shadow: 0 2px 5px rgba(0,0,0,0.5);
   cursor: pointer;
   z-index: 1050; /* Ensure it's above the overlay */
 }
 
.Sermon .overlay {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(0,0,0,0.5);
   z-index: 1040;
 }
 
 .fa-folder {
   color: #801214;
   font-size: 2em;
 }

 .sermon-item {
   background: #FFFFFF;
   border-radius: 10px;
   margin: 20px 50px;
   padding: 20px;
   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
   max-width: 360px;
   text-align: center;
   font-family: 'Comfortaa', cursive;
 }
 
 .sermon-date, .sermon-title, .sermon-files a {
   display: block;
   margin: 10px 0;
   color: #333;
   text-decoration: none;
   font-size: 16px;
 }
 
 .sermon-files a {
   background: #f2f2f2;
   border-radius: 5px;
   padding: 10px;
   color: #801214;
 }
 
 .sermon-files a:hover {
   background: #dbdbdb;
 }
 
 .fa-calendar, .fa-note-sticky, .fa-music {
   margin-right: 8px;
 }

 .sort-options {
   display: flex;
   justify-content: center;
   margin-bottom: 20px;
 }
 
 .sort-options select {
   font-family: 'Comfortaa', cursive;
   background: #f2f2f2;
   border: 2px solid #801214; 
   color: #333;
   padding: 10px;
   margin: 0 10px;
   border-radius: 5px;
   cursor: pointer;
   outline: none;
 }
 
 .sort-options select:focus {
   background: #dbdbdb;
 }
 
 .sort-options select:hover {
   background: #dbdbdb;
 }
 
 .sort-options option {
   background: white;
 }

 .toast-message {
   position: fixed;
   bottom: 20px;
   left: 50%;
   transform: translateX(-50%);
   background-color: #333;
   color: #fff;
   padding: 10px 20px;
   border-radius: 5px;
   z-index: 1060;
 }

 .delete-sermon-button {
   font-family: 'Comfortaa', cursive;
   margin-top: 10px;
   background-color: transparent;
   cursor: pointer;
 }
 
 .delete-sermon-button:hover {
   color: darkred;
 }

 .form button, .sermon-form button, .view-pdf-button {
  font-family: 'Comfortaa', cursive;
  text-transform: uppercase;
  outline: 0;
  background: #801214;
  border: 0;
  border-radius: 5px;
  padding: 10px 15px;
  margin: 5px;
  color: white;
  font-size: 14px;
  cursor: pointer;
  width: 50%;
}

.view-pdf-button:hover {
  background:#b34345;
}

.clear-view-button {
  font-family: 'Comfortaa', cursive;
  text-transform: uppercase;
  background: #801214;
  color: #FFFFFF;
  border: 0;
  border-radius: 5px;
  padding: 5px 10px;
  margin-top: 15px;
  margin-left: 84%;
  font-size: 12px; 
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.clear-view-button:hover {
  background-color: #b34345;
}

.pdf-viewer{
  position: absolute;
  height: 80%;
  width: 50%;
  left: 40%;
  top: 38%;
}