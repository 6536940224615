.react-calendar {
   font-family: 'Comfortaa', cursive;
   margin-left: 5%;
   width: 90%;
   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
   border: none;
}

.react-calendar__tile--active, 
.react-calendar__tile--select {
  background-color: transparent !important;
  color: inherit;
}

.react-calendar__tile--now {
   background: #801214 !important;
   color: white !important;
}

.event-calendar button {
   font-family: 'Comfortaa', cursive;
   text-transform: uppercase;
   outline: 0;
   width: 10%;
   border: 0;
   border-radius: 5px;
   padding: 15px;
   color: #801214;
   font-size: 14px;
   transition: all 0.3s ease;
   cursor: pointer;
}

.event-calendar button:hover {
  background: #dbdbdb;
}

.event-calendar .delete-event-button {
  background-color: transparent;
  color: #801214;
  border: none;
  padding: 5px;
  margin-top: 30px;
}

.event-calendar .add-event-button{
   margin-left: 2%;
   background-color: transparent;
   color: #801214;
   border: none;
   padding: 5px;
   margin-top: 10px;
   margin-bottom: -3%;
}

.event-calendar .input-add-event{
   align-items: center;
}

.event-calendar .add-event-container {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   gap: 10px;
 }

.event-calendar input[type="text"] {
   font-family: 'Comfortaa', cursive;
   width: 30%;
   padding: 10px;
   margin: 10px 0;
   border: 2px solid #801214;
   border-radius: 5px;
   outline: none;
   transition: all 0.3s ease;
 }
 
 .event-calendar input[type="text"]:focus {
   border-color: #dbdbdb;
 }

 .event-calendar .p{
   font-family: 'Comfortaa', cursive;
 }