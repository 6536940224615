@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Judson:ital,wght@0,400;0,700;1,400&display=swap');

.Events .navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: black;
}

.Events .nav-links :hover {
  background-color: rgb(46, 46, 46);
}

.Events h1{
  padding-top: 70px;
  padding-left: 80px;
  font-family: 'Judson', serif;
  color: #801214;
}

.event h2, .event-details h2 {
  color: #801214;
}

.Events .form-container{
  display: flex;
  justify-content: center;
  align-items: center; 
  height: 100vh;
  flex-direction: column;
}

.Events .form, .events-form {
  position: relative;
  background: #FFFFFF;
  border-radius: 10px;
  max-width: 360px;
  margin: 0 auto;
  padding: 45px;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.form input, .events-form input, .events-form select {
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  border-radius: 5px;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
  font-family: 'Comfortaa', cursive;
}

.form input:focus, .events-form input:focus, .events-form select:focus {
  background: #dbdbdb;
}

.form button, .events-form button {
  font-family: 'Comfortaa', cursive;
  text-transform: uppercase;
  outline: 0;
  background: #801214;
  width: 100%;
  border: 0;
  border-radius: 5px;
  padding: 15px;
  color: #FFFFFF;
  font-size: 14px;
  transition: all 0.3 ease;
  cursor: pointer;
}

.form button:hover, .events-form button:hover {
  background:#b34345;
}

.Events .event-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.Events .event {
  border-radius: 10px;
  margin-right: 50px;
  margin-left: 20px;
  padding: 10px 100px;
  max-width: 500px;
  min-height: 400px;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0,0,0,0.5);
}

.Events .photos img, .my-slider .slick-slide img {
  width: 100%;
  height: auto;
  object-fit: contain;
  object-position: center;
  overflow: hidden;
}

.my-slider {
  height: 300px; /* Adjust this as needed */
  overflow: hidden; /* Ensure no overflow */
}

.my-slider .slick-slide img {
  width: 100%;
  height: 100%;
  max-height: 300px;
  object-fit: contain;
  object-position: center;
}

.my-slider .slick-prev,
.my-slider .slick-next {
  z-index: 10;
  border-radius: 50%;
  background-color: rgba(0,0,0,0.1);
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0,0,0,0.5);
}

.my-slider .slick-prev {
  left: 20px;
}

.my-slider .slick-next {
  right: 20px;
}

.delete-event-button{
  font-family: 'Comfortaa', cursive;
  margin-top: 5%;
  margin-bottom: 5%;
  background-color: transparent;
  cursor: pointer;
}

.Events .white-box {
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 60px;
  height: 60px;
  border-radius: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0,0,0,0.5);
  cursor: pointer;
}

.fa-image {
  color: #801214;
  font-size: 2em;
}

.form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.events-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.dates-list {
  flex: 0 1 150px;
  margin-right: 10px;
  margin-left: 38px;
  padding: 20px 10px 20px 20px;
  border-radius: 10px;
  font-family: 'Comfortaa', cursive;
  overflow-y: auto;
}

.dates-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.dates-list .date {
  padding: 10px;
  margin-bottom: 10px;
  color: #801214;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dates-list .date:hover {
  background-color: #dbdbdb;
}

.dates-list li:last-child {
  margin-bottom: 0;
}

.toast-message {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 1060;
}