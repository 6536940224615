.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  height: 40px;
  position: fixed;
  width: 100%;
  top: 0;
  transition: background-color 0.5s ease;
  z-index: 1000;
}

.navbar .title {
  padding-top: 0;
}

.church-name a {
  text-decoration: none;
}

.church-name a,
.church-name a:visited,
.church-name a:hover,
.church-name a:active,
.church-name a:focus {
  text-decoration: none;
  color: White;
}

.transparentNav {
  background-color: transparent;
}

.transparentNav .nav-links .directory {
  padding: 20px;
}

.darkNav {
  background-color: black;
}

.darkNav .church-name, .darkNav .nav-links a {
  color: white;
  text-align: center;
}
.darkNav .nav-links .directory {
  padding: 20px;
}
.darkNav .nav-links :hover {
  background-color: rgb(46, 46, 46);
  
}

.title {
  margin: 10%;
  width: 50%;
  height: auto;
}

.toast {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 1000;
  animation: fadeOut 3s forwards;
}

@keyframes fadeOut {
  0% { opacity: 1; }
  80% { opacity: 1; }
  100% { opacity: 0; }
}

.navbar button {
  font-family: 'Judson', serif;
  font-size: medium;
  font-weight: bold;
  background: none;
  border: none;
  color: white;
  padding-right: 15px;
}
